<template>
  <div>
    <v-index>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教务</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">学年学期</a></el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">教学周</a></el-breadcrumb-item>
      </el-breadcrumb>

      <div class="week-list">
        <div class="week-item">
          <div class="week-item-title">
          </div>
          <template v-for="weekIndex in 6">
            <div class="week-day header">
              周{{['一', '二', '三', '四', '五', '六'][weekIndex - 1]}}
            </div>
          </template>
          <div class="week-day header">周日</div>
        </div>
        <template v-for="(item, weekIndex) in list">
          <div class="week-item">
            <div class="week-item-title">
              第{{weekIndex}}周
            </div>
            <template v-for="day in 6">
              <div class="week-day">
                <el-date-picker size="small" value-format="yyyy-MM-dd" style="width: 140px" :value="item[day]" @input="onChangeDate(weekIndex, day, $event)" />
              </div>
            </template>
            <div class="week-day">
              <el-date-picker size="small" value-format="yyyy-MM-dd" :value="item[7]" @input="onChangeDate(weekIndex, 7, $event)" style="width: 140px" />
            </div>

            <div class="week-day">
              <el-button type="text" danger @click="onDel(weekIndex)">删除</el-button>
            </div>
          </div>
        </template>
      </div>

      <div class="footer">
        <el-button type="primary" @click="onSave" :loading="subing">保存设置</el-button>
        <el-button @click="onAdd" :loading="subing">增加周</el-button>
        <el-button @click="onInit">重新初始化</el-button>
      </div>
    </v-index>
  </div>
</template>

<script>
import VIndex from "@/components/common";
import dayjs from "dayjs";
import _ from "lodash";
import v2Semester from "@/api/v2/semester";
export default {
  name: "week",
  components: {VIndex},
  data() {
    return {
      id: '',
      list: {},
      subing: false
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getSemesterInfo(this.id)
  },
  methods: {
    // 获取学期数据
    async getSemesterInfo(id) {
      const resp = await this.$api.setting.getSemesterDetail(id)
      if (resp.data.code === 1) {
        this.list = {};
        for (let weekIndex in resp.data.data.week_data) {
          const item = {};
          for (let day in resp.data.data.week_data[weekIndex]) {
            item[day] = dayjs(resp.data.data.week_data[weekIndex][day] * 1000).format('YYYY-MM-DD')
          }
          this.list[weekIndex] = item
        }
      }
    },
    onAdd() {
      this.$set(this.list, _.size(this.list) + 1, {})
    },
    // 日期切换
    onChangeDate(weekIndex, day, value) {
      if (this.list[weekIndex]) {
        if (value) {
          this.$set(this.list, weekIndex, {
            ...this.list[weekIndex],
            [day]: value,
          })
        } else {
          const newValue = { ...this.list[weekIndex] }
          delete newValue[day]
          this.$set(this.list, weekIndex, newValue)
        }
      } else {
        this.$set(this.list, weekIndex, {
          [day]: value,
        })
      }
      this.$forceUpdate()
      console.log('week', this.list)
    },
    // 初始化
    async onInit() {
      this.subing = true
      await v2Semester.create(this.id)
          .finally(() => this.subing = false)
      this.$message.success('初始化成功')
      this.getSemesterInfo(this.id)
    },
    // 保存
    async onSave() {
      const data = {}
      for (let weekIndex in this.list) {
        const item = {}
        for (let day in this.list[weekIndex]) {
          item[day] = dayjs(this.list[weekIndex][day]).unix()
        }
        data[weekIndex] = item
      }
      this.subing = true
      await v2Semester.save(data)
          .finally(() => this.subing = false)
      this.$message.success('保存成功')
    },
    /**
     * 删除
     */
    onDel(weekIndex) {
      this.$delete(this.list, weekIndex)
    }
  }
}
</script>

<style scoped lang="scss">
.week-list {
  margin-top: 30px;
  background: #fff;
  padding: 16px;
}
.week-day {
  width: 150px;
  display: inline-block;
}
.week-item {
  padding: 10px;
  margin-right: 10px;
  &-title {
    display: inline-block;
    width: 140px;
  }
}
.footer {
  background: #fff;
  padding-left: 170px;
  padding-bottom: 30px;
}
</style>